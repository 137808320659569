
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import {ClientSelect, LoadFilterObjects} from "@/core/composite/composite";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormUser from "@/views/user/FormUser.vue";
import UserService from "@/core/services/UserService";
import Swal from "sweetalert2";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import CookieService from "@/core/services/CookieService";
import {COOKIE_KEY} from "@/core/config/Constant";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import UserStatus from "@/views/user/UserStatus.vue";

export default defineComponent({
  name: "Users",
  components: {UserStatus, BaseSelect, FormUser, BaseModal, QuickAction, DateTimeFormat, KtDatatable, Entities},
  setup: function () {
    const store = useStore();
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("User",
        [
          {link: false, router: '', text: 'List'}
        ]
      )
    })

    const state = reactive({
      user: {}
    });
    const currentUser = computed(() => store.getters.currentUser);
    const filterObject = ref({companyCode: 'TR', status: 'ACTIVATED'})
    const userPage = computed(() => store.state.UserModule.userPage)
    const tableHeader = ref([

      {
        name: "FullName",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Company",
        key: "company",
        sortable: true,
      },
      {
        name: "Last Login",
        key: "lastLogin",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      filterObject,
      userPage,
      tableHeader,
      state,
      currentUser,
      ...LoadFilterObjects(Actions.LOAD_USERS, filterObject.value, ['company']),
      store,
      ...ClientSelect(true)
    }
  },
  methods: {
    onUpdate(user) {
      this.state.user = user;
    },
    onUpdateStatus(user, status) {
      UserService.updateStatus(user.id, status).then(() => {
        this.paginationLoad()
      })
    },
    onSaveUser() {
      const baseModal = this.$refs.userForm as typeof BaseModal
      baseModal.hideBaseModal()
      this.paginationLoad()
    },
    onRestPassword(user) {
      Swal.showLoading()
      UserService.resetPassword(user.id).then(() => {
        Swal.fire({
          title: 'Reset Password Success',
          text: '',
          icon: 'success',
          heightAuto: false,
        })
      })
    },
    deleteUser(user) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await UserService.delete(user.id).then(() => {
            this.paginationLoad();
          })
        }
      })
    },
    async onSwitch(user) {
      await UserService.impersonal(user.name).then(res => {
        JwtService.setOldToken();
        JwtService.saveToken(res.accessToken);
        ApiService.setHeader();
        CookieService.save(COOKIE_KEY.IMPERSONAL, true)
        this.store.commit('SET_IMPERSONAL', true)
        this.store.dispatch(Actions.LOAD_PROFILE)
        this.$router.push("/dashboard");
      })
    },
    discardEvent() {
      this.filterObject.companyCode = 'TR'
      this.filterObject.status = 'ACTIVATED'
      this.updateFilterObject(this.filterObject);
    },
    searchEvent() {
      this.updateFilterObject(this.filterObject)
    },
  },

})
